<template>
  <div class="row">
    <template v-for="(vehicle, index) in source.row">
      <div class="col-md-6 col-lg-4" :data-index="index">
        <a
          v-if="vehicle"
          :href="vehicle.url"
          class="vehicle-tile"
          data-barba-prevent
        >
          <div class="vehicle-tile-image">
            <div
              v-if="vehicle.isHot || vehicle.carIsOnSale || vehicle.carIsNew"
              class="list-tag"
              :class="vehicle.isHot ? 'is-hot' : vehicle.carIsOnSale ? 'is-sale' : 'is-new' "
            >
              
              <svg
                enable-background="new 0 0 15.6 28.3"
                viewBox="0 0 15.6 28.3"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMaxYMid"
              >
                <path
                  d="m15.5 28.3s-1.5-.7-3.5-2.2c0 0-1.9-1.6-2.4-2.1-3.9-3.8-8.7-12.7-9.6-24h15.5z"
                />
              </svg>
              <span>
                <svg
                  v-if="vehicle.isHot"
                  style="margin-top: -10px !important"
                  version="1.1"
                  viewBox="0 0 1200 1200"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="m914.4 374.4c-46.801 76.801-42 160.8-40.801 177.6-168-225.6-218.4-374.4-195.6-496.8-128.4 50.402-266.4 243.6-284.4 399.6-63.602-49.199-39.602-165.6-20.398-195.6-186 186-242.4 409.2-207.6 540 36 130.8 130.8 255.6 271.2 336h27.602c-4.8008-7.1992-8.3984-15.602-10.801-22.801-44.402-134.4 74.398-174 74.398-231.6 34.801 21.602 33.602 46.801 20.398 82.801 42-26.398 72-109.2 55.199-164.4 80.398 39.602 105.6 156 112.8 192 3.6016-31.203 3.6016-61.203 50.402-79.203-10.801 49.199 74.398 105.6 57.602 176.4-3.6016 15.602-9.6016 30-16.801 43.199 152.4-90 252-210 234-362.4-18-156-140.4-308.4-127.2-394.8z"
                  />
                </svg>
                {{
                  vehicle.isHot
                    ? 'Hot STOCK'
                    : vehicle.carIsOnSale
                    ? 'sale'
                    : 'new'
                }}
              </span>
            </div>
            <div
              v-if="vehicle.img"
              class="bg-image"
              :style="{ backgroundImage: `url(${vehicle.img})` }"
            ></div>
            <div v-if="vehicle.carIsPerformance" class="performance">
              <svg
                enable-background="new 0 0 150 29.3"
                viewBox="0 0 150 29.3"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m47.6 8.9-.5 2.6h-5.3l-.3 1.8h4.8l-.4 2.5h-4.8l-.3 2h5.2l-.5 2.6h-8.3l2-11.5z"
                />
                <path
                  d="m50.7 16.6-.7 3.8h-3l2-11.5h5c2.3 0 3.7 1.2 3.7 3.4 0 2.1-1.2 3.4-2.9 4l2.1 4.1h-3.2l-1.8-3.8zm.4-2.4h1.9c1.2 0 1.8-.6 1.8-1.7 0-.7-.4-1.1-1.4-1.1h-1.8z"
                />
                <path
                  d="m57.8 20.4 2-11.5h8.2l-.5 2.6h-5.1l-.4 2.3h4.7l-.5 2.6h-4.7l-.7 4.1h-3z"
                />
                <path
                  d="m67.9 15.4c0-3.8 2.7-6.7 6.1-6.7 3.2 0 5.2 2.2 5.2 5.2 0 3.8-2.7 6.7-6.1 6.7-3.2 0-5.2-2.2-5.2-5.2zm8.2-1.4c0-1.6-.9-2.7-2.2-2.7-1.7 0-3 1.5-3 3.9 0 1.6.9 2.7 2.2 2.7 1.7.1 3-1.4 3-3.9z"
                />
                <path
                  d="m83.1 16.6-.7 3.8h-3l2-11.5h5c2.3 0 3.7 1.2 3.7 3.4 0 2.1-1.2 3.4-2.9 4l2.1 4.1h-3.2l-1.8-3.8zm.4-2.4h1.9c1.2 0 1.8-.6 1.8-1.7 0-.7-.4-1.1-1.4-1.1h-1.8z"
                />
                <path
                  d="m95.4 20.4-1.2-6.9-1.2 6.9h-2.8l2-11.5h3.6l1.3 7.2 3.8-7.2h3.7l-2 11.5h-2.8l1.2-6.9-3.6 6.9z"
                />
                <path
                  d="m113.2 8.9 2.2 11.5h-3l-.4-2.2h-4.2l-1.2 2.2h-3l6.5-11.5zm-4 6.8h2.4l-.6-3.2z"
                />
                <path
                  d="m126.6 20.4h-2.3l-3.7-6.9-1.2 6.9h-2.9l2-11.5h2.7l3.4 6.6 1.2-6.6h2.9z"
                />
                <path
                  d="m134.9 8.7c2.9 0 4.5 1.7 4.7 4.1h-3c-.2-.8-.8-1.4-1.8-1.4-1.8 0-2.9 1.7-2.9 3.9 0 1.6.9 2.7 2.2 2.7 1 0 1.7-.5 2.1-1.4h3c-.8 2.4-2.4 4.1-5.2 4.1-3.2 0-5.2-2.2-5.2-5.2 0-3.8 2.6-6.8 6.1-6.8z"
                />
                <path
                  d="m150 8.9-.5 2.6h-5.3l-.3 1.8h4.8l-.4 2.5h-4.8l-.3 2h5.3l-.5 2.6h-8.3l2-11.5z"
                />
                <path
                  d="m33.6 8.9h-3.2c-.6 4.5-1.9 8.1-3.5 10.9l-.1.6h3l.6-3.6h2.1c2.7 0 4.9-1.6 4.9-4.5 0-2.2-1.4-3.4-3.8-3.4zm-1 5.4h-1.7l.5-2.9h1.7c1 0 1.4.5 1.4 1.2 0 1.1-.7 1.7-1.9 1.7z"
                />
                <path
                  d="m3.3 16.5-.1-.1c-.1-.1 0-.1 0-.1s2.7.3 2.8.3.2.1.2.1c.4 1 1.3 1.5 1.3 1.5 4.5 2.3 7.9.8 7.9.8s1.4-.5 1.6-1.2c.8-2.3-3.7-2.9-7.8-4s-5.6-2-5.6-2c-1.6-1-2.1-2.2-2-3.7.1-1 .4-1.9 1.7-2.9-2 .9-3.3 1.6-3.3 1.6.8 10.3 5.3 16.3 8.8 19.5zm15.6 3.7s-5.2 2.8-11.2.2l1.5 3.8c.5.2 1.6.6 4 .6s3.8-.5 4.3-.7zm-5.7 9.1s1.3-.6 3-1.9l.9-2.2c-.5.3-1.9.8-3.9.8s-3.1-.5-3.6-.7l1.1 2.6c1.5.9 2.5 1.4 2.5 1.4zm2.1-18.7s9.7 1.9 7 7.5c-1.2 2.4-3.1 5.8-4.1 7.5 3.4-3.3 7.4-9.2 8.2-18.8 0 0-.6-.3-1.6-.8l-1.1 2.9-2.7.1c-.8-1.5-3-2.6-5.7-3.1-3-.6-5.3.2-5.3.2s-4.9 2.1 5.3 4.5z"
                />
              </svg>
            </div>
          </div>
          <div class="vehicle-tile-body">
            <div class="is-bold3 vehicle-tile-title">{{ vehicle.title }}</div>
            <p class="text-gray2 t-sm">{{ vehicle.text }}</p>
            <div class="vehicle-tile-prices">
              <div class="vehicle-tile-price">
                <div>Buy now</div>
                <div class="price">
                  {{ vehicle.price | money }}
                  <div v-if="vehicle.carIsOnSale" class="vehicle-tile-sale">
                    {{ vehicle.priceWas | money }}
                  </div>
                </div>
              </div>
              <div class="vehicle-tile-price text-red">
                <div><i>Super Finance</i></div>
                <div class="price">
                  <i>{{ vehicle.priceFinance | money }}<span> weekly*</span></i>
                </div>
              </div>
            </div>
            <div
              class="vehicle-tile-finance"
              :class="vehicle.carIsOnSale ? 'mt-4' : 'mt-2'"
              v-text="vehicle.financeDesc"
            ></div>
          </div>
        </a>
      </div>
      <div class="col-sm-12" v-if="index == 5" v-html="getBanner()"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  methods: {
    getBanner: function () {
      return this.$root.$data.banner;
    },
  },
  props: {
    index: {
      type: Number,
    },
    source: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  filters: {
    money(val) {
      return val ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
    },
  },
};
</script>
